import React, { useEffect, useRef, useState, useCallback } from "react";

interface Props {
  alt: string;
  src: string;
  style?: {};
}
const SvgImage = (props: Props) => {
  const baseStyle = {};
  let style = props.style ? Object.assign({baseStyle}, props.style):Object.assign({baseStyle});
  return (
    <>
      <img src={props.src} alt={props.alt} style = {style}/>
    </>
  );
};
SvgImage.defaultProps = {
  src: "",
  alt: "",
  style: {},
};

export default SvgImage;

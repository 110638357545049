import React, { useEffect, useRef, useState, useCallback } from "react";
import InView, { useInView } from "react-intersection-observer";
interface Props {
  videoRef: any;
  src: {
    mp4: any;
    webm: any;
  };
}

function filteredProps(props: Props) {
  const filteredProps = { ...props };
  delete filteredProps.videoRef;
  delete filteredProps.children;
  delete filteredProps.src;
  return filteredProps;
}

export const Video = (props: Props) => {
  const videoRef = useRef(null);
  const wasInView = useRef(false);
  const options = {
    rootMargin: "0px",
    threshold: 1.0,
    triggerOnce: true,
  };
  const [ref, inView, entry] = useInView(options);
  const setRef = useCallback((element: HTMLElement) => {
    if (videoRef.current !== element) {
      ref(element);
      videoRef.current = element;
      if (props.videoRef) {
        props.videoRef(element);
      }
    }
  }, []);
  if (!wasInView.current && inView) {
    wasInView.current = true;
    videoRef.current.load();
  }

  return (
    <>
      <video ref={setRef} {...filteredProps(props)}>
        {props.src.mp4 && (
          <source
            data-src={props.src.mp4}
            src={wasInView.current ? props.src.mp4 : null}
            type="video/mp4"
          />
        )}
        {props.src.webm && (
          <source
            data-src={props.src.webm}
            src={wasInView.current ? props.src.webm : null}
            type="video/webm"
          />
        )}
      </video>
    </>
  );
};
